@import 'fonts.scss';

.saveTAModal {
  :global(.modal-dialog) {
    max-width: 600px !important;
  }
  label[for='taName'] {
    padding-left: 0;
    padding-right: 0;
  }

  input.error {
    border-color: $danger;
  }

  .errorMessage {
    color: $danger;
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
  }

  :global(.row) {
    margin-bottom: 0px;
  }
}