@import "../../colors.scss";
@import "../../components.scss";

.style-page {
  @include main-container;
  height: 100%;
  padding: 30px;

  h4 {
    margin: 20px auto;
  }

  .note {
    @include caption-font;
    color: $danger;
    margin-top: 10px;
  }

  .btn {
    margin: 5px;
  }

  .progress {
    width: 300px;
  }

  .demo-panel {
    padding: 30px;
    background-color: $white;
  }

  .color-palette {
    @include body-font;
    height: 48px;
    line-height: 48px;
    color: $white;
    display: inline-block;
    padding-left: 10px;
  }

  .toggle {
    margin-right: 30px;
  }

  .main {
    width: 100px;
    margin-right: 20px;
  }

  .secondary {
    width: 48px;
  }

  .theme1 {
    background-color: $theme1;
  }

  .theme2 {
    background-color: $theme1;
  }

  .theme3 {
    background-color: $theme3;
  }

  .theme4 {
    background-color: $theme4;
  }

  .theme5 {
    color: $dark;
    background-color: $theme5;
  }

  .primary1 {
    background-color: $primary1;
  }

  .primary2 {
    background-color: $primary2;
  }

  .primary3 {
    background-color: $primary3;
  }

  .primary4 {
    background-color: $primary4;
  }

  .primary5 {
    color: $dark;
    background-color: $primary5;
  }

  .black {
    background-color: $black;
  }

  .dark {
    background-color: $dark;
  }

  .light {
    background-color: $light;
  }

  .super-light {
    background-color: $super-light;
  }

  .danger {
    background-color: $danger;
  }

  .light-danger {
    background-color: $light-danger;
  }

  .success {
    background-color: $success;
  }

  .light-success {
    background-color: $light-success;
  }

  .warning {
    background-color: $warning;
  }

  .light-warning {
    background-color: $light-warning;
  }

  .info {
    background-color: $info;
  }

  .light-info {
    background-color: $light-info;
  }

  .font-sample {
    padding: 10px;
    margin: 20px 0px;
  }

  .body-font {
    @include body-font;
  }

  .headline-font {
    @include headline-font;
  }

  .subheading-font {
    @include subheading-font;
  }

  .button-font {
    @include button-font;
  }

  .caption-font {
    @include caption-font;
  }

  .text {
    @include text;
  }

  .body-link {
    @include body-link;
  }

  .body-link-hover {
    @include body-link-hover;
  }

  .body-black {
    @include body-black;
  }

  .body-black-medium {
    @include body-black-medium;
  }

  .body-dark {
    @include body-dark;
  }

  .body-dark-medium {
    @include body-dark-medium;
  }

  .body-super-light {
    @include body-super-light;
  }

  .headline-black {
    @include headline-black;
  }

  .subheading-success {
    @include subheading-success;
  }

  .subheading-dark {
    @include subheading-dark;
  }

  .subheading-danger {
    @include subheading-danger;
  }

  .subheading-warning {
    @include subheading-warning;
  }

  .subheading-info {
    @include subheading-info;
  }

  .button-dark {
    @include button-dark;
  }

  .button-white {
    @include button-white;
    background-color: $theme1;
  }

  .basic-input {
    width: 500px;
  }
}
