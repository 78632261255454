@import 'fonts.scss';

.container {
  position: relative;
  background-color: $white2;
  min-width: 1452px;
  min-height: 100%;
  height: 1px;
  display: flex;
  flex-direction: column;
  .titleArea {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 10px 30px 0 30px;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $white;
    margin-bottom: 20px;
    z-index: 3;
    position: relative;

    .title {
      display: flex;
      padding: 20px 0px 20px 0px;
      margin-bottom: 4px;
      height: 62px;
    }

    .filtersFormContainer {
      padding-bottom: 14px;
    }

    .fieldContent {
      overflow: visible;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: row;
      height: 34px;
      min-width: max-content;
      margin-bottom: 20px;

      :global {
        .row {
          height: 34px;
          margin-right: 12px;
          margin-left: 0px;
          label {
            margin-right: 12px;
            width: 69px;
            justify-content: flex-end;
          }
        }
        .col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      &.priceMargin {
        :global{
          .row {
            margin-right: 4px;
          }
        }
      }
      &.productHomeFilterAdder {
        margin-left: 78px;
        height: 34px;
      }

      .filterAdder {
        display: flex;
        align-items: center;
        padding: 0px 12px;
  
        svg {
          font-size: 14px;
          @include base-font;
          color: $primary3;
        }
  
        :global(.dropdown-toggle) {
          padding: 0;
          padding-left: 6px;
        }
  
        :global(.dropdown-item:hover) {
          background-color: $theme5;
          color: $black;
        }
  
        a::after {
          display: none;
        }
      }
    }
    .fieldContent {
      &.productFilterAdder {
        margin-left: 78px;
      }
      :global {
        .row {
          margin-bottom: 20px;
        }
      }
    }
    .productHomeSelect {
      :global(.react-select__control) {
        min-height: 34px;
      }
      :global(.react-select__indicators) {
        > div {
          margin-bottom: 2px;
        }
      }
      height: 34px;
    }
    .priceInput {
      border-radius: 4px;
    }
    .tilde {
      display: flex;
      justify-content: center;
      width: 22px;
      margin-left: 0px;
      margin-right: 4px;
      line-height: 34px;
    }
  }
}