@import 'colors.scss';
@import 'fonts.scss';

.modal {

  ::-webkit-scrollbar {
    width: 0px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c2c1c1; 
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    border-top: 1px solid $super-light;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: $light;
  }
  
  color: #333;
  font-size: 14px;

  h4 {
    font-size: 18px;
    margin: 0px;
  }

  .label {
    font-weight: bold;
  }

  .value {
    margin: 0px 0px 10px 0px;
  }

  .modal-footer {
    > div {
      display: flex;
      flex-direction: row-reverse;
    }
    button {
      margin: 0;
      margin-left: 20px;
    }
  }

  &.fullScreen,
  &.sliderScreen {
    .modal-dialog {
      margin: 0;
      max-width: 100%;
      min-height: 100%;
      height: 100%;
      max-height: fit-content;
    }
    .modal-content {
      border: none;
      border-radius: 0;
      box-shadow: none;
      height: 100%;
      .modal-header {
        @include headline-font;
      }
      .modal-body {
        padding: 0px;
        overflow: auto;
      }
      .modal-footer {
        padding-left: 30px;
        padding-right: 10px;
        height: 74px;
        border-color: $super-light;
        box-shadow: 0 2px 5px 0 rgba(97, 95, 103, 0.5);
        z-index: 2;
        justify-content: space-between;
        > div {
          display: flex;
          flex-direction: row;
        }
        button {
          margin: 0;
          margin-right: 20px;
        }
      }
    }
  }
  &.fullScreen {
    margin: 0 0 0 42px;
    max-width: calc(100% - 42px);
    &.menuBarExpand {
      margin: 0 0 0 200px;
      max-width: calc(100% - 200px);
    }
  }
  &.sliderScreen {
    margin: 0 0 0 calc(100% - 600px);
    max-width: 600px;
  }
}