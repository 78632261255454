@import 'fonts.scss';

.keywordSettingContainer {
  background-color: $white3;
  width: 100%;
  display: flex;
  flex-direction: column;
  .inputArea {
    flex: 1;
    padding: 25px 34px 40px;
    border-right: 1px solid $super-light;
    background-color: $white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    .descript {
      @include base-font;
      font-size: 16px;
      color: #2c2c2e;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .col {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 150px;
      border-radius: 2px;
      border: 1px solid #DDDDDD;
      padding: 8px 30px 8px 10px;
      overflow-y: auto;
      cursor: pointer;
      input {
        border: none;
        height: fit-content;
        padding: 0px;
        @include base-font;
        font-size: 13px;
        color: $black;
        font-weight: 300;
        &::placeholder {
          font-size: 13px;
          color: $black;
        }
      }
      .tags {
        width: 100%;
      }
      .clearButton {
        position: absolute;
        top: 8px;
        right: 9px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        color: rgba(63, 72, 75, 1);
      }
      &.hasError {
        border-color: $danger;
        margin-bottom: 5px;
      }
    }
    .keywordsProposalsArea {
      display: flex;
      align-items: center;
      @include base-font;
      font-size: 14px;
      margin-top: 10px;

      .proposalsButton {
        &:disabled {
          color: $white;
          border: rgba($primary6, 0.12);
          background-color: rgba($primary6, 0.12);
          cursor: default;
        }
      }
      .keywordsProposalsDes {
        color: $black;
        text-align: center;
        padding: 10px;
        flex-shrink: 1;
      }
    }
    :global {
      .react-tagsinput-input {
        display: none;
      }
      .react-tagsinput {
        border: none;
        padding: 0px;
        > span {
          margin: 0px;
        }
      }
      .react-tagsinput-tag {
        height: fit-content;
      }
    }
    :global(.errorTip) {
      margin-top: -5px;
      font-size: 12px;
      color: $danger;
    }
  }
  .preselectArea {
    flex: 1;
    padding: 25px 34px 40px;
    border-right: 1px solid $super-light;
    border-bottom: 1px solid $super-light;
    background-color: $white;

    .titleArea {
      position: relative;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      background-color: $white;
      .title {
        @include base-font;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #2C2C2E;
        margin-bottom: 6px;
      }
      .subTitle {
        @include base-font;
        color: #979797;
        font-size: 12px;
      }
    }

    .col {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 150px;
      border-radius: 2px;
      border: 1px solid #DDDDDD;
      padding: 8px 30px 8px 10px;
      overflow-y: auto;
      .tags {
        width: 100%;
      }
      .clearButton {
        position: absolute;
        top: 8px;
        right: 9px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        color: rgba(63, 72, 75, 1);
        cursor: pointer;
      }
      &.hasError {
        border-color: $danger;
        margin-bottom: 5px;
      }
    }
    :global {
      .react-tagsinput-input {
        display: none;
      }
      .react-tagsinput {
        border: none;
        padding: 0px;
        > span {
          margin: 0px;
        }
        cursor: default;
      }
      .react-tagsinput-tag {
        height: fit-content;
      }
    }
    :global(.errorTip) {
      margin-top: -5px;
      font-size: 12px;
      color: $danger;
    }
  }
  .trendingKeywordsListArea {
    flex: 1;
    flex-direction: column;
    height: 82px;
    padding: 20px 34px;
    border-right: 1px solid $super-light;
    border-bottom: 1px solid $super-light;;
    background-color: $white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .keywordsListArea {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 32px;
      .descript {
        @include base-font;
        font-size: 14px;
        color: #2c2c2e;
        font-weight: 500;
        margin-right: 30px;
        width: fit-content;
      }
      .keywordsList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        .capsule {
          height: 100%;
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 14px;
          font-weight: 500;
          color: $theme1;
          border: 1px solid $theme1;
          border-radius: 20px;
          padding: 6px 8px 6px 12px;
          cursor: pointer;
          > span {
            margin-right: 4px;
          }
        }
        :not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
  .suggestKeywordsListArea {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    max-height: 500px;
    border-right: 1px solid $super-light;

    .contentArea {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 424px;
      overflow: auto;
      
      > div {
        padding-bottom: 0px;
        background-color: $white;
        height: 418px;
      }
    }
    tr {
      :first-child:not(.selectClickBox) {
        padding-left: 0px;
      }
      th {
        height: 56px;
        color: #2C2C2E;
        font-size: 12px;
        font-weight: 400;
      }
    }
    th, td {
      vertical-align: middle;
      padding: 4px 10px;
    }
    .selectClickBox {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin-right: 10px;
      .addIcon, 
      .addAllIcon {
        width: 16px;
        height: 16px;
        border-radius: 20px;
        color: $theme1;
        border: 1px solid $theme1;
        cursor: pointer;
        &.disabled {
          color: $theme6;
          border: 0px solid $theme6;
          cursor: default;
        }
      }
    }
    .nameHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .nameCell {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      .info {
        .name {
          @include base-font;
          width: 240px;
          font-size: 13.5px;
          font-weight: 500;
          color: $black;
          word-break: break-word;
        }
      }
    }
    .bidPriceCell {
      width: 56px;
      padding: 0px 10px;
      text-align: start;
      color: #979797;
    }
    .keywordSearchVolumeCell,
    .growthRateCell {
      text-align: start;
      color: #979797;
    }
    .keywordAdCompetitionCell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      :nth-child(1) {
        background-color: #DAEFFD;
      }
      :nth-child(2) {
        background-color: #A2D8FA;
      }
      :nth-child(3) {
        background-color: #279BF0;
      }
      :nth-child(4) {
        background-color: #077CE5;
      }
      :nth-child(5) {
        background-color: #0A57C2;
      }
      .fillRateBlock {
        width: 10px;
        height: 16px;
      }
    }
    .suggestKeyword {
      width: 477px;
    }
    .bidPrice {
      width: 130px;
      padding-right: 20px;
    }
    .keywordSearchVolume {
      width: 170px;
      padding-right: 20px;
    }
    .last7DayGrowthRate {
      width: 130px;
      padding-right: 40px;
    }
    .last30DayGrowthRate {
      width: 130px;
      padding-right: 20px;
    }
    // .keywordAdCompetition {
    //   width: 170px;
    //   padding-right: 20px;
    // }
  }
}

.selectedProductsArea {
  border: 1px solid $super-light;
  background-color: $white;
  border-left: none;
  border-right: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden; 
  margin-bottom: 40px;
}
