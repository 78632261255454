@import 'fonts.scss';

.keywordsProposalsListArea {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid $super-light;
  .titleArea {
    display: flex;
    flex-direction: column;
    .editArea {
      height: 77px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $super-light;
      button {
        margin-left: 30px;
      }
    }
    .searchArea {
      height: 56px;
      display: flex;
      align-items: center;
      :global(.search-bar) {
        width: 320px;
        margin: 0px 15px 0px auto;
      }
    }
  }

  .contentArea {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    > div {
      padding-bottom: 0px !important;
    }
    :global {
      .react-bootstrap-table-pagination {
        position: sticky;
        left: 0;
        bottom: 0;
        z-index: 5;
        height: 74px;
        border-top: 1px solid $super-light;
        margin-left: 0px;
        margin-right: 0px;
        background-color: $white3;
      }
      .react-bootstrap-table-pagination-list {
        bottom: 21px !important;
      }
      .col {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  tr {
    th {
      height: 56px;
      color: #2C2C2E;
      font-size: 12px;
      font-weight: 400;
      padding: 16px 10px;
    }
  }
  th, td {
    vertical-align: middle;
    padding: 4px 10px;
  }
  .nameHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 48px;
  }
  .nameCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    .selectCheckBox {
      min-width: 48px;
      height: auto;
      padding-left: 3px;
      input {
        display: none;
      }
      input + label {
        margin-right: 0;
      }
      input:checked + label::before,
      input:indeterminate + label::before {
        background-color: $theme1;
        border: 1px solid $theme1;
      }
    }
    .info {
      .name {
        @include base-font;
        width: 240px;
        font-size: 13.5px;
        font-weight: 500;
        color: $black;
        word-break: break-word;
      }
    }
  }
  .searchKeyword {
    @include base-font;
    width: 477px;
    font-size: 13.5px;
    font-weight: 500;
    color: $black;
    word-break: break-word;
  }
}
