@import 'colors.scss';

.progress {
  height: 8px;
  border-radius: 5px;
  border: 1px solid $success;
  div {
    background-color: $success;
    height: 100%
  }
  &.danger {
    border-color: $danger;
    div {
      background-color: $danger;
    }
  }
  &.deepWarning {
    border-color: $deep-warning;
    div {
      background-color: $deep-warning;
    }
  }
  &.warning {
    border-color: $warning;
    div {
      background-color: $warning;
    }
  }
  &.notStart {
    border-color: $super-light;
    div {
      background-color: $super-light;
    }
  }
}