@import 'fonts.scss';

.tableCell {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.limitationContent {
  margin-right: 10px;
  max-width: 250px;
  min-width: 150px;
  display: grid;
  grid-template-columns: auto 12px 1fr;
  .limitationTitle {
    grid-column: 1 / 2;
  }
  .colon {
    grid-column: 2 / 3;
    white-space: pre;
  }
  .limitationValue {
    min-width: 80px;
    grid-column: 3 / 4;
  }
  & > div {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $dark;
    span.general {
      color: $dark;
    }
    span.include {
      color: $success;
    }
    span.exclude {
      color: $danger;
    }
    span.preferred {
      color: #72c5e2;
    }
    span.nonePreferred {
      color: $warning;
    }
  }
}
.limitationDetailBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 300px;
  width: max-content;
  height: auto;
  background: $white;
  box-shadow: 0px 4px 16px 0 rgba(97, 95, 103, 0.5);
  top: -70px;
  z-index: 3;
  .toggleDetail {
    width: auto;
    padding: 8px 0px 6px 0px;
  
    .closeBtn {
      display: inline;
      color: $dark;
      font-size: 20px;
      padding: 0px;
      margin-left: 10px;
    }
  }
  .limitationDetail {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0px 20px 10px 20px; 
  
    .title {
      display: flex;
      align-items: center;
      color: $dark;
      font-size: 15px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      margin: 2.5px 0px;
      &::before {
        width: 10px;
        height: 20px;
        margin-right: 6px;
        background: $success;
        content: '';
      }
    }
  
    .general::before {
      background: $dark;
    }
  
    .include::before {
      background: $success;
    }
  
    .exclude::before {
      background: $danger;
    }
  
    .preferred::before {
      background: $info;
    }
  
    .nonPreferred::before {
      background: $warning;
    }
  
    .content {
      padding: 5px 0px;
  
      .statement {
        margin: 2.5px 0px;
      }
    }
  }
}
