@import 'colors.scss';

.radioColumn {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  :global(.form-check) {
    padding-left: 0;
  }
  :global(.form-check-label) {
    margin: 0px;
  }

  .radio {
    position: relative;
    padding: 3px 0px;
  }
  .hint {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
  }
  .hoverHint {
    visibility: hidden;
    margin-left: 10px;
    font-size: 10px;
    font-weight: 500;
    color: $theme1;
    position: absolute;
    height: 35px;
    line-height: 35px;
    left: 30%;
    top: 0;
    white-space: nowrap;
  }
  .radio:hover {
    .hoverHint {
      visibility: visible;
    }
  }
}

:global {
  .form-check-input:disabled ~ .form-check-label {
    cursor: default;
  }
}