@import 'fonts.scss';

.titleWithBreadcrumbs {
  background-color: $white;
  height: 118px;
  flex-shrink: 0;
  position: relative;
  .title {
    @include base-font;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    left: 30px;
    top: 30px;
    position: absolute;
  }
  .breadcrumbs {
    left: 30px;
    bottom: 20px;
    position: absolute;
  }
}