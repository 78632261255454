@import 'fonts.scss';

.productListOverview {
  background-color: $theme1;
  width: 100%;
  .operateArea {
    padding-left: 30px;
    color: $white;
    height: 38px;
    display: flex;
    align-items: center;
    position: relative;
    .selectedInfo {
      @include base-font;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-right: 10px;
      cursor: default;
    }
    div {
      margin-right: 25px;
      @include base-font;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      justify-self: flex-end;
      cursor: pointer;
    }
    .removeAll {
      position: absolute;
      right: 30px;
    }
  }
  .seperationLine {
    max-height: 1px;
    width: 100%;
    height: 1px;
    background-color: $white;
    opacity: 0.5;
    transition: all 0.15s ease;
  }
  .overviewArea {
    .arrowRight {
      width: 0; 
      height: 0; 
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid $white;
    }
    
    .arrowLeft {
      width: 0; 
      height: 0; 
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent; 
      border-right: 5px solid $white; 
    }

    display: flex;
    flex-direction: row;
    max-height: 92px;
    transition: all 0.15s ease;
    overflow: hidden;
    padding: 0px 5px;
    color: $white;
    .overview {
      display: flex;
      flex-direction: row;
      min-width: max-content;
      .overviewLeft {
        position: relative;
        .checkbox {
          position: absolute;
          top: -3px;
          left: 5px;
        }
        img {
          width: 75px;
          height: 75px;
        }
      }
      .overviewRight {
        padding-left: 10px;
        .productName {
          @include base-font;
          padding-right: 5px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 110px;
          word-break: break-all;
          font-size: 12px;
          font-weight: 400;
          height: 57px;
          line-height: 18px;
        }
        .productId {
          @include base-font;
          font-size: 10px;
          font-weight: 500;
          line-height: 18px;
          color: $super-light;
          height: 18px;
          vertical-align: middle;
        }
      }
    }
    .arrowLeftContainer {
      margin-right: 8px;
      height: 75px;
    }
    .arrowRightContainer {
      margin-left: 8px;
      height: 75px;
    }
  }
}