.productNativeCreativeDetailContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // img {
  //   max-width: 50vw;
  //   max-height: 50vh;
  // }
  .imageCreativeDetail {
    width: max-content;
    height: max-content;
  }
}