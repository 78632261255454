@import 'colors.scss';
@import 'fonts.scss';

.normalCreativePreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
  }
}

.richProductNativeCreativePreview {
  overflow: hidden;
  &.width2440 {
    pointer-events: none;
    width: 230px;
    height: 23px;
    iframe {
      transform: scale(0.19);
      transform-origin: 0 0;
    }
  }
  &.width960 {
    pointer-events: none;
    width: 200px;
    height: 67px;
    iframe {
      transform: scale(0.54);
      transform-origin: 0 0;
    }
  }
}
