@import 'colors.scss';

.imageFormInputWithList {
  display: flex;
  .selectImageList {
    padding-left: 20px;
    color: $dark;
    :global(.input-group-append) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .imageInput {
    position: relative;
    box-sizing: content-box;
    border: dashed 1px $super-light;
    background-color: $white;
    width: max-content;
    max-width: 500px;
    max-height: 500px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &.previewMultipleInput, &.emptyImageInput {
      width: 424px;
      height: 170px;
    }
    &.showSolidBorder {
      border: 1px solid $super-light;
    }
    &.showErrorBorder {
      border-color: $danger;
    }
  }
}