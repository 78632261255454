@import 'colors.scss';
@import 'fonts.scss';

.container {
  min-width: min-content;
  overflow-x: auto;
  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 40px 0 16px 30px;
  }

  .content {
    background-color: $white;
    padding: 30px;
    
    .categoryTitle {
      @include base-font;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.18;
      color: $black;
      margin: 10px 0 10px 0;
      display: flex;
      align-items: center;

      .backBtn  {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $primary1;
        cursor: pointer;
        margin-left: 30px;
      }
    }

    .categoryTitle::after {
      content: '';
      flex: 1;
      margin-left: 20px;
      border-top: solid 1px $super-light;
    }
  }

  .buttonArea {
    margin: 60px 0px 84px 30px;
    button {
      margin-right: 20px;
    }
  }
}