@import 'fonts.scss';
@import 'colors.scss';
.datePickerInput {
  max-width: 424px; 
  &.error {
    border: 1px solid $danger;
  }
}
.datePickerContainer {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  .shortcuts {
    padding-right: 20px;
    .shortcutItem {
      @include base-font;
      color: $dark;
      margin-top: 10px;
      cursor: pointer;
      max-width: 100px;
      overflow-wrap: break-word;
      &:hover {
        color: $light;
      }
    }
  }

  :global {
    .react-datepicker {
      width: max-content;
      border: none;
      .react-datepicker__month-container {
        height: 290px;
        .react-datepicker__header {
          background-color: transparent;
          border-bottom: 1px solid $super-light;
          .react-datepicker__month-read-view--down-arrow {
            margin-left: 30px;
          }
          .react-datepicker__month-dropdown, .react-datepicker__year-dropdown {
            background-color: $white;
            @include base-font;
            color: $dark;
            text-align: left;
            padding: 5px 0px;
            .react-datepicker__month-option {
              padding-left: 15px;
              padding-right: 15px;
              &:hover {
                background-color: $theme3;
              }
            }
            .react-datepicker__month-option--selected_month {
              background-color: $theme1;
              color: $white;
              .react-datepicker__month-option--selected {
                display: none;
              }
            }
          }
          .react-datepicker__year-dropdown {
            padding: 0px;
            .react-datepicker__year-option {
              padding-left: 15px;
              padding-right: 15px;
              &:hover {
                background-color: $theme3;
              }
            }
            .react-datepicker__year-option--selected_year {
              background-color: $theme1;
              color: $white;
              .react-datepicker__year-option--selected {
                display: none;
              }
            }
          }
        }
        .react-datepicker__day--in-selecting-range {
          background-color: $theme3;
        }
        .react-datepicker__day--in-range {
          background-color: $theme1;
          color: $white;
        }
        .react-datepicker__day--range-start {
          background-color: $theme1;
        }
        .react-datepicker__day--range-end {
          background-color: $theme1;
        }
        .react-datepicker__day--disabled {
          opacity: 0.5;
        }
      }
      .react-datepicker__time-container  {
        border-left: 1px solid $super-light;
        border-right: 1px solid $super-light;
        .react-datepicker__header--time {
          background-color: $white;
          border-bottom: 1px solid $super-light;
          display: none;
        }
        .react-datepicker__time .react-datepicker__time-box {
          ul.react-datepicker__time-list {
            height: 290px !important;
            li.react-datepicker__time-list-item--selected {
              background-color: $theme1;
            }
          }
        }
      }
    }
  }  
}
