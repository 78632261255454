@import 'fonts.scss';

.purposeInput {
  padding-right: 55px !important;
}
.countLimit {
  position: absolute;
  z-index: 5;
  left: 380px;
  font-weight: bold;
}
.bidAdjustmentsContainer {
  position: relative;
}
.adjustmentAdder {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  color: $primary3;
  cursor: pointer;
  @include base-font;

  svg {
    font-size: 14px;
    padding-right: 6px;
    width: auto !important;
  }
}
.removeFilterBtn {
  font-size: 14px;
  @include base-font;
  color: $theme1;
  margin-left: 7px;
  margin-top: 2px;
  cursor: pointer;
}