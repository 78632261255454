@import 'components.scss';

.cell {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  .hoverBtns {
    color: $dark;
  }
}
