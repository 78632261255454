@import 'colors.scss';

.search-bar {
  height: 34px;
  margin: auto 30px;
  border-radius: 2px;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  border: 1px solid $super-light;

  &.disabled {
    background-color: $light-pink;
    &:hover {
      border: 1px solid $super-light;
    }
  }

  span {
    color: $dark;
    margin: auto 10px;
  }

  input {
    border: 0 !important;
    padding: 0;
    width: 100%;
    background: transparent;
    &:hover {
      border: 0;
    }
  }

  &:hover {
    border: 1px solid $dark;
  }

  &.focused {
    border: 1px solid $theme1;
  }

  .invisible {
    opacity: 0;
  }

  .visible {
    cursor: pointer;
  }
}