@import 'fonts.scss';

.bidPriceSetting {
  @include base-font;
  font-size: 14px;
  color: $dark;
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  > fieldset {
    border: 1px solid $super-light;
    padding: 10px 20px !important;
    margin-top: 10px !important;
    margin-bottom: 30px;
    width: 380px;
    .subTitle {
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .planType {
      label {
        color: $black;
      }
    }
    .autoBidCapContainer, .bidFloorContainer {
      margin-top: 5px;
      :global(.form-group) {
        margin-bottom: 0;
      }
    }
    .optimizeContainer {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      > span {
        width: 150px;
      }
      :global(.input-group) {
        width: 162px;
        margin-left: 12px;
      }
      :global(.form-group) {
        margin-bottom: 0;
      }
    }
  }
}
