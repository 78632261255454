@import 'colors.scss';

.actor-permission-form {
  padding-top: 30px;
  padding-bottom: 40px;
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .error-message {
    color: $danger;
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
  }
  .account-email {
    width: 480px;
    &.error {
      border-color: $danger;
    }
  }
  .permission-list {
    width: 480px;
  }
}