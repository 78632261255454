@import 'fonts.scss';
.searchBarContainer {
    border-bottom: 1px solid $super-light;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    > :global(.search-bar) {
        width: 370px;
    }
}

.accountListContainer {
    border-top: 1px solid $super-light;
    height: calc(100% - 109px);
    > div {
        padding-bottom: 0px !important;
        height: 100%;
    }
}

.accountListContainer td {
    vertical-align: middle;
}

.sudoNameCell {
    display: flex;
    align-items: center;
    .name {
        @include base-font;
        width: 172px;
        font-size: 14px;
        line-height: 1.57;
        color: $theme1;
        padding: 0;
    }
    .id {
        @include base-font;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.2;
        color: $dark;
        padding: 4px 0px;
    }
}


.selectCheckBox {
    position: relative;
}
