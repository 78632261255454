@import 'fonts.scss';

.app-menu-item {
  display: flex;
  align-items: center;
  color: $dark;
  min-height: 42px;
  overflow: hidden;
  min-width: 100%;

  a,div {
    width: 100%;
    @include medium;
    @include body-font;
    color: $dark;
    display: block;
    text-overflow: ellipsis;
    text-decoration: none;
    padding-left: 45px;
    div {
      word-break: break-word;
      padding: 5px 0px;
    }
  }

  a:visited {
    color: inherit
  }

  &.now {
    background-color: rgba($theme8, 0.1);
    a {
      color: $theme1;
    }
  }
}


.app-menu-item:hover {
  background-color: rgba($black2, 0.1);
}