@import 'fonts.scss';
@import 'colors.scss';

.orderDetailContainer {
  background-color: $white2;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: min-content;
  :global(.dropdown-menu) {
    transform: translate3d(-25px, 25px, 0px) !important;
    min-width: auto;
  }
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .buttonArea {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 30px;
      flex-shrink: 0;
      &>div,
      &>a {
        cursor: pointer;
        margin-left: 30px;
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $theme1;
      }
      &>div {
        >a {
          padding: 0;
        }
      }
      a:hover, div:hover {
        color: $theme1;
      }
      .approveBtn {
        background-color: $success;
        color: $white;
        padding: 5px 10px;
        border-radius: 2px;
        &:active {
          background-color: darken($success, 15%);
        }
        &.disabled:hover {
          background-color: $success;
          color: $white;
        }
        &.disabled:active {
          background-color: $success;
        }
        &.disabled {
          cursor: default;
          opacity: 0.7;
        }
      }
      .rejectBtn {
        background-color: $warning;
        color: $white;
        padding: 5px 10px;
        margin-left: 10px;
        margin-right: -10px;
        border-radius: 2px;
        &:hover {
          background-color: $warning;
          color: $white;
        }
        &:active {
          background-color: darken($warning, 15%);
        }
      }
      .settleBtn>span {
        display: none;
      }
      .disable {
        color: gray;
        position: relative;
        &:hover>span {
          display: block;
        }
        span {
          display: none;
          position: absolute;
          top: -27px;
          right: -142px;
          width: max-content;
          color: $dark;
          background-color: $white;
        }
      }
      .disable:active {
        pointer-events: none;
        &>span {
          display: block;
        }
      }
      .cursorNotAllow {
        cursor: not-allowed;
      }
    }
  }
  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }
  .detail {
    margin-left: 30px;
    display: none;
    &.show {
      display: block;
    }
  }
  .basic, .performance, .finalReport {
    display: flex;
    flex-wrap: wrap;
  }
  .orderName {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
    padding: 20px 0px 11px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 10px;
    }
  }
  .field {
    flex-grow: 0;
    min-width: 20%;
    padding: 0;
    padding-right: 30px;
    margin: 10px 0;
  }
  .fieldLabel,
  .fieldValue,
  .fieldHint {
    @include base-font;
    margin: 0;
    padding: 0;
    max-width: none;
    label {
      @include base-font;
      margin: 0;
      font-weight: 500;
      line-height: 1.17;
    }
  }
  .fieldLabel {
    font-size: 12px;
    color: $light;
  }
  .fieldValue {
    font-size: 14px;
    color: $dark;
    line-height: 1.57;
    margin-top: 2px;
    white-space: pre-line;
    width: fit-content;
    > svg {
      margin-left: 5px;
      color: $light;
      cursor: pointer;
    }
  }
  .fieldHint {
    font-size: 12px;
    color: $light;
    line-height: 1.17;
    margin-top: 2px;
  }
  .changeValue {
    color: $black;
  }
  .performanceField {
    color: $black;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2px;
  }
  .sepLine {
    height: 1px;
    border-top: 1px solid $super-light;
  }
  form {
    margin: 0px 28px 0px 26px;
  }
  :global(.nav) {
    padding-left: 30px;
    background-color: $white;
  }
  .other {
    overflow: hidden;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 28px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    background-color: $white2;
  }
  .orderDetail {
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
}

:global {
  .tooltip {
    &[id*="-orderExtraInfo"] {
      .tooltip-inner {
        text-align: left;
        @include base-font;
        font-size: 12px;
        pointer-events: none;
        max-width: 300px;
        ul {
          padding-left: 20px;
          padding-right: 10px;
          margin-top: 8px;
          margin-bottom: 8px
        }
      }
    }
  }
}