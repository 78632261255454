@import 'fonts.scss';
@import 'components.scss';

.app-menu {
  
  color: $black;
  cursor: pointer;

  .app-menu-sub-title-bar {
    @include medium;
    @include body-font;
    display: flex;
    height: 42px;

    .app-menu-sub-title {
      margin: auto 0px;
      width: 100%;
    }

    .arrow {
      margin: auto 20px auto auto;
    }

    .up {
      @include arrow-up($black);
    }

    .down {
      @include arrow-down($black);
    }

    .app-menu-icon {
      margin: auto 15px;
    }
  }

  .app-menu-icon-only {
    height: 42px;
    line-height: 42px;
    text-align: center;

    .app-menu-icon {
      margin: auto;
    }
  }

  .app-menu-sub-title-bar:hover {
    cursor: pointer;
  }

  .minimized {
    display: none;
  }

  .hovered {
    @include floating-menu($white3);
    margin-left: 42px;
    margin-top: -42px;
  }
}
