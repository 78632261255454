@import 'colors.scss';

.actor-menu-item {

  display: flex;
  color: $dark;
  padding: 0px 12px;
  cursor: pointer;
  height: 42px;

  // .actor-icon {
  //   width: 1.1em;
  //   font-size: 12pt;
  //   margin: auto 0.2em;
  // }
}

.actor-menu-item:hover {
  color: $black;
  background-color: $white2;
}
