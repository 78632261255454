@import 'fonts.scss';
@import 'components.scss';

.actor-menu {
  margin: 0px;
  padding-left: 15px;

  .current-actor {
    height: 50px;
    display: flex;
    
    color: $white;
    cursor: pointer;

    .arrow {
      @include arrow-down($white);
      margin: auto 15px;
    }
  }
  &:hover {
    background-color: $theme8;
  }
}

.drop-down {
  @include floating-menu($white);
  margin-left: -15px;
  overflow: auto;
  max-height: 75vh;
}

.hidden {
  display: none;
}
