@import 'fonts.scss';


.container {
  background-color: $white2;
  height: 100%;

  :global(.form-group.row) {
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 auto;
  }
}

.titleArea {
  color: $black;
  height: 84px;
  padding: 30px;
  box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
  background-color: $white;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;

  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
  }
}

.contentArea {
  background-color: $white;
  height: calc(100% - 124px);
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  span.errorHint {
    @include base-font;
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: $danger;
    margin-top: 2px;
    width: 100%;
  }
}
