@import 'fonts.scss';

.page {
  min-height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .topArea {
    background-color: $white;
    min-height: 84px;
    &.topAreaWithPadding {
      padding: 30px;
    }
    &.topAreaEndWithShadow {
      box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    }
    &.spaceBetweenTopAreaAndContent {
      margin-bottom: 30px;
    }
  }
  .mainArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    > div {
      flex-grow: 1;
    }
  }
}

.pageSection {

  h2 {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 41px 0 16px 30px;
  }

  .sectionContent {
    flex-grow: 1;
    background-color: $white;
    padding: 40px 30px;
    min-width: min-content;
    &.withoutPadding {
      padding: 0;
    }
  }

  &.withShadow {
    &.sectionContent {
      box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
    }
  }

  .hintModal {
    flex-shrink: 0;
    border-left: 1px solid $super-light;
    background-color: $white;
    box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
  }
}

.buttonArea {
  position: relative;
  margin: 30px 0 30px 30px;
  background-color: $white3;
  
  button {
    margin-right: 20px;
  }
}
