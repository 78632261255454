@import 'fonts.scss';

.creativeSummaryStateContent {  
  .title {
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: $dark;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 0px 40px 30px;
    
    .titlePrefix {
      width: 6px;
      height: 20px;
      background-color: $light;
      margin-right: 6px;
    }
  }
  .details {
    margin-bottom: 9.5px;
    margin-left: 60px;
    margin-right: 120px;
    line-height: 25px;
    .creativeName {
      text-align: right;
      overflow-wrap: break-word;
    }
    .header {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #7bc8ff;
      color: $black;
      display: flex;
      justify-content: space-between;
    }
    .content {
      padding-top: 20px;
      padding-bottom: 20px;
      color: $dark;
      border-bottom: 1px solid rgba(97, 95, 103, 0.5);
      display: flex;
      justify-content: space-between;
      .creativeArea {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .nameArea {
        padding-right: 30px;
        max-width: 20%;
        display: flex;
        align-items: center;
        div {
          overflow-wrap: break-word;
          max-width: 100%;
        }
      }
    }
    .floatRight {
      display: flex;
      align-items: center;
    }
    .stateFilter {
      cursor: pointer;
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      color: $white;
      height: 22px;
      border-radius: 11px;
      line-height: 22px;
      padding: 0px 10px;
      box-sizing: content-box;
      width: fit-content;
      margin-right: 3px;
      background-color: $light;
      &.active {
        background-color: $theme1;
      }
    }
    .stateContent {
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      color: $white;
      height: 22px;
      border-radius: 11px;
      line-height: 22px;
      padding: 0px 10px;
      box-sizing: content-box;
      width: fit-content;
      background-color: $black;
      &.active {
        background-color: $info;
      }
      flex-shrink: 0;
      margin: auto;
      margin-left: 5px;
    }
  }
  .modifyHint {
    @include base-font;
    color: $light;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 20px;
    margin-left: 60px;
  }
}