@import 'fonts.scss';

.container {
  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 40px 0 16px 30px;
  }

  .content {
    background-color: $white;
    padding: 30px;
    section {
      min-width: 300px;
      position: relative;
    }
    .basicSummary {
      .categoryTitle {
        margin-top: 30px;
      }
      .summaryDataContent {
        display: flex;
        justify-content: space-between;
        .smartHelperData {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 200px;
          // max-height: 246px;
          height: max-content;
          padding: 30px;
          border: 1px solid $super-light;
          margin-right: 86px;
          .smartHelperTitle {
            flex-wrap: wrap;
            text-align: start;
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #000000;
          }
          .smartHelperContent {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .dataBlock {
              width: 100%;
              .blockTitle {
                height: 20px;
                flex-wrap: wrap;
                text-align: start;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: $light;
                margin-bottom: 8px;
              }
              .blockContent {
                display: flex;
                height: 36px;
                width: max-content;
                padding: 10px 20px 10px 0px;
                align-items: center;
                align-self: stretch;
                font-size: 20px;
                font-weight: 400;
                line-height: 110%;
                color: $theme1;
              }
            }
            :not(:last-child) {
              margin-bottom: 8px;
            }
          }

          .saveTaHint {
            @include base-font;
            font-size: 12px;
            line-height: 1.42;
            color: $dark;
            margin-top: 20px;
            margin-bottom: 10px;
          }

          button {
            white-space: nowrap;
          }
        }
      }
    }
    .limitationSummary {
      .categoryTitle {
        margin: 30px 0px 24px 0px;
      }
    }
    .categoryTitle {
      @include base-font;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.18;
      color: $black;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      .backBtn  {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $primary1;
        cursor: pointer;
        margin-left: 30px;
      }
    }
    .categoryTitle::after {
      content: '';
      flex: 1;
      margin-left: 20px;
      border-top: solid 1px $super-light;
    }
    .campaignAnalyticsHint {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: $theme1;
      > img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .buttonArea {
    margin: 30px 0px 30px 30px;
    button {
      margin-right: 20px;
    }
  }

  :global(.nav-item.nav-link.active) {
    background-color: $white3;
    color: $theme1;
  }
  
  :global(.nav-item.nav-link) {
    height: 38px;
    color: $dark;
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }
  
  :global(.nav-item.nav-link:hover:not(.active)) {
    box-sizing: border-box;
    border-bottom-color: transparent;
    height: 100%;
  }
  
  :global(.nav-tabs) {
    padding-top: 27px;
    padding-left: 28px;
    height: 64px;
  }
  
  :global(.tab-content fieldset) {
    margin-top: 40px;
    padding-left: 0;
    padding-right: 0;
  }
}

:global {
  .tooltip {
    &#uniqueUser, &#impression {
      .tooltip-inner {
        text-align: left;
        @include base-font;
        font-size: 12px;
        pointer-events: none;
      }
    }
  }
}