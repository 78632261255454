@import 'colors.scss';
@import 'components.scss';

.agencyMemberTab {
  height: 100%;
  display: flex;
  flex-direction: column;
  .operationPanel {
    background-color: $white;
    border-bottom: 1px solid $super-light;
    height: 66px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    > button {
      margin-right: 30px;
      @include createButton;
    }
    :global(.search-bar) {
      width: 280px;
      margin: 0px;
      position: absolute;
      right: 40px;
    }
  }
  .listContainer {
    @include list-container;
  }
}