@import 'fonts.scss';

.field {
  flex-grow: 0;
  min-width: 20%;
  padding: 0;
  padding-right: 30px;
  margin: 10px 0;
}
.fieldLabel,
.fieldValue,
.fieldHint {
  margin: 0;
  padding: 0;
  max-width: none;
  label {
    @include base-font;
    margin: 0;
    font-weight: 500;
    line-height: 1.17;
  }
}

.fieldLabel {
  font-size: 14px;
  color: $dark;
  line-height: 1.57;
  margin-top: 2px;
  font-weight: bold;
}
.from {
  font-size: 12px;
  color: $dark;
  display: flex;
  white-space: pre-line;
  max-width: 100%;
}
.to {
  font-size: 12px;
  color: $dark;
  margin-top: 2px;
  word-break: break-word;
  display: flex;
  white-space: pre-line;
  max-width: 100%;
}
.from,
.to {
  .value {
    width: 100%;
  }
}

.campaignBidPrice {
  display: flex;
  flex-direction: column;
  .settings {
    display: flex;
    margin-left: 10px;
    > span {
      width: 45%;
    }
    .bidPriceContainer {
      display: flex;
      flex-direction: column;
      width: 25%;
      .bidPrice {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.srcInfo {
  .bottomInfo {
    @include base-font;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    color: $dark;
    padding: 4px 0px;
  }
}

.detailRenderer {
  display: flex;
  flex-wrap: wrap;
  .frequency {
    display: grid;
    grid-column-gap: 3px;
    .label {
      grid-column: 1 / 2;
    }
    .seperator {
      grid-column: 2 / 3;
    }
    .value {
      grid-column: 3 / 4;
    }
  }
}