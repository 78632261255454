
@import 'fonts.scss';

.recommendSizeContent {
  display: grid;
  width: 364px;
  grid-template-rows: 50px 50px 50px;
  grid-template-columns: 120px 120px 120px;
  grid-row-gap: 22px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $dark;
    border: 1px solid $super-light;
    align-self: start;
    justify-self: center;
  }
  .size960x320 {
    width: 125px;
    height: 42px;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
  .size2440x250 {
    width: 406px;
    height: 42px;
    grid-row: 2 / 3;
    grid-column: 1 / 4;
  }
}