@import 'colors.scss';

.chooseProductsStep {
  width: 100%;
  height: 100%;
  background-color: $white2;
  position: relative;
  min-width: 300px;
}

.productList {
  background-color: $white;
  height: 100%;
  // :global {
  //   .react-bootstrap-table-pagination {
  //     height: 96px !important;
  //   }
  //   .react-bootstrap-table-pagination-list {
  //     bottom: 32px !important;
  //   }
  // }
}

.buttonArea {
  position: absolute;
  bottom: 0px;
  margin: 8px 0 8px 30px;
  z-index: 6;
  button {
    margin-right: 20px;
  }
}