@import '../../fonts.scss';
@import 'components';

.datePicker {
  z-index: 1060;
}

.downloadReportPanel {
  @include tabs;
  :global(.nav) {
    margin-top: 30px;
  }

  .content {
    padding: 40px 110px 40px 30px;
  }

  :global(.modal-body) {
    height: 190px;
  }

  @include base-font;
  .title {
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid $super-light;
    margin-bottom: 20px;
  }

  .label {
    font-size: 14px;
    margin: 10px 20px 10px 0px;
    width: 80px;
    text-align: end;
    color: $dark;
  }

  button {
    margin-right: 10px;
  }

  .reportTypeSelection {
    width: 390px;
  }

  .quickSelect,
  .fieldChecks,
  .groupBy {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
  }

  .quickSelect {
    min-height: 36px;
    > div {
      color: $theme1;
      cursor: pointer;
      margin-right: 30px;
    }
  }

  .dataContent {
    margin-bottom: 0;
  }

  .audienceDataHint {
    position: absolute;
    left: -8px;
    top: 12px;
    z-index: 3;
  }

  .fieldChecks {
    :global(.form-check) {
      padding: 0;
      margin-top: 6px;
      margin-bottom: 6px;
      width: 170px;
      > label {
        color: $dark;
      }
    }
    .error {
      label {
        color: $danger;
      }
      input[type=checkbox] + label:before {
        border: 1px solid $danger;
      }
    }
  }

  .errorContainer {
    margin-top: 10px;
  }

  .errorHint {
    display: block;
    @include base-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
    color: $danger;
  }

  label[for='quickSelect'],
  label[for='videoAdViewObjective'],
  label[for='audienceData'] {
    color: $dark;
  }

  .dividerContainer {
    :global(.col-sm-9) {
      div:first-child {
        grid-template-columns: auto max-content;
      }
    }
  }
  .divider {
    border-top: 1px solid $super-light;
  }

  .scheduleRadios {
    display: flex;
    margin-left: 80px;
  }

  :global(.bp3-input-group) {
    width: 195px;
  }
  
  .groupBy {
    :global(.form-check) {
      padding: 0;
      > label {
        color: $dark;
      }
    }
    height: 36px;
  }
}
