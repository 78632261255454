@import 'fonts.scss';

.app-menu-bar {
  height: 100%;
  background-color: $white3;
  transition: all .3s ease-in;
  position: sticky;
  top: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;

  .app-menu-bar-title {
    display: flex;
    min-height: 54px;
  }

  .menus {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    flex-grow: 1;
  }
}

.expanded {
  width: 200px;
  min-width: 200px;
}

.collapsed {
  width: 42px;
  min-width: 42px;

  .menus {
    overflow-y: hidden;
  }
}