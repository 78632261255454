@import 'colors';
@import 'components';

.container {
  background-color: $white2;
  > header {
    @include panel($white);
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 0;
    position: relative;
    z-index: 3;
  }
}

.innerContent {
  @include tabs;

  :global(.nav) {
    padding-top: 30px;
    background-color: $white;
  }
  
  > form {
    fieldset {
      margin-top: 40px;
      padding-left: 0;
      padding-right: 0;
    }
    legend > span {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: $black;
      margin-left: 30px;
    }
    @include tab-form-content;
    .fieldContent {
      overflow: visible;
    }
  }

  button {
    color: #fff;
  }

  .buttonArea {
    button,
    a {
      margin-right: 20px;
    }
    margin: 60px 0 120px 30px;
  }
}


.agencyFrom {
  @include tab-form-content;
  .fieldContent {
    overflow: visible;
  }
}

.systemProfitMonitorPercent {
  input {
    border: 1px solid $super-light !important;
  }
  :global(.input-group-text){
    border: 1px solid $super-light;
  }
}

.autoHeight {
  > div {
    height: auto !important;
  }
}

.autoWithSelect {
  width: auto;
}

.subTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  label {
    text-align: left !important;
    padding-left: 0 !important;
  }
  span {
    margin-right: 10px;
  }
}

.manualForm {
  :global(.input-group) {
    width: 150px;
  }
  label {
    text-align: left !important;
    padding-left: 0 !important;
  }
}
.formLabel {
  text-align: left;
  color: $dark;
  height: 35px;
  line-height: 35px;
}
.row {
  position: relative;
  button {
    position: absolute;
    top: 0px;
    left: 150px;
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $dark;
    height: 35px;
    margin-left: 20px;
  }
}

.bidPriceSettings {
  margin-top: 5px;
}

.campaignTypesInfo {
  padding: 7px 15px 7px 10px;
  font-family: NotoSansSC, 'Noto Sans SC', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  word-spacing: 1px;
  color: $dark;
}