@import 'fonts.scss';

.account-summary {
  width: 100%;

  .summary-row {
    display: flex;
    flex-wrap: wrap;

    .summary-column {
      flex-grow: 0;
      min-width: 20%;
      padding: 0;
      padding-right: 30px;
      margin: 10px 0;

      .summary-label,
      .summary-value {
        margin: 0;
        padding: 0;
        max-width: none;
        label {
          @include base-font;
          margin: 0;
          font-weight: 500;
          line-height: 1.17;
        }
      }
      .summary-label {
        font-size: 12px;
        color: $light;
      }
      .summary-value {
        font-size: 14px;
        color: $dark;
        line-height: 1.57;
        margin-top: 2px;
        word-break: break-all;
      }
    }
  }
}
