@import 'colors.scss';
@import 'fonts.scss';

.formSection {
  h2 {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 41px 0 16px 30px;
  }

  .formContent {
    background-color: $white;
    box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
    padding: 40px 30px;
    padding-right: 110px;

    &.showHint {
      display: flex;
      padding: 0px;
      min-width: 1336px;
      > div:first-child {
        flex-grow: 1;
        padding: 40px 30px;
      }
      > div:nth-child(2) {
        flex-shrink: 0;
        flex-basis: 700px;
        border-left: 1px solid $super-light;
      }
    }
  }
}
.hint {
  display: block;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #98969d;
  margin-top: 2px;
  width: 100%;
}