@import 'fonts.scss';
@import 'colors.scss';

.chooseAdTypeStep {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    @include base-font;
    margin-top: 40px;
    margin-left: 30px;
    margin-bottom: 10px;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
  }
  
  .buttonArea {
    padding: 20px;
    min-height: 558px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    .buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-width: 660px;
      max-width: 660px;
      &.center {
        justify-content: center;
      }
    }
  }
}
