@import "./fonts.scss";
@import "./colors.scss";
@import "~bootstrap/scss/bootstrap.scss";

.btn, .btn:focus-visible {
  @include button-font;
  border-radius: 2px;
  color: $white;
  &:focus {
    box-shadow: none;
  }
}

.btn.disabled, .btn:disabled {
  opacity: 0.5;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-sm {
  padding: 10px 30px;
}

.btn-lg {
  padding: 14px 40px;
}

.btn-primary, .btn-primary:focus {
  border-color: $primary1;
  background-color: $primary1;
}

.btn-primary:hover {
  color: $white;
  border-color: $primary2;
  background-color: $primary2;
}

.btn-primary:disabled, .btn-primary.disabled {
  color: $white;
  border: rgba($primary6, 0.12);
  background-color: rgba($primary6, 0.12);
  opacity: 1;
}

.btn-primary:not(:disabled):not(.disabled):active {
  border-color: $primary4;
  background-color: $primary4;
}

.btn-secondary, .btn-secondary:focus {
  color: $dark;
  border: 1px solid $dark;
  background-color: $white;
}

.btn-secondary:hover {
  color: $dark;
  border: 1px solid $dark;
  background-color: rgba($primary2, 0.08);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: rgba($dark, 0.16);
  border-color: rgba($dark, 0.16);
  background-color: $white;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  color: $dark;
  border-color: $dark;
  background-color: rgba($primary2, 0.12);
}

.btn-tertiary, .btn-tertiary:focus {
  border-color: $primary1;
  background-color: $primary1;
}

.btn-tertiary:hover {
  color: $white;
  border-color: $primary2;
  background-color: $primary2;
}

.btn-tertiary:disabled, .btn-tertiary.disabled {
  color: $white;
  border: rgba($primary6, 0.12);
  background-color: rgba($primary6, 0.12);
  opacity: 1;
}

.btn-tertiary:active, .btn-tertiary:not(:disabled):not(.disabled):active {
  border-color: $primary4;
  background-color: $primary4;
}

.btn-danger, .btn-danger:focus {
  border-color: $danger;
  background-color: $danger;
}

.btn-danger:hover {
  border-color: $light-danger;
  background-color: $light-danger;
}

.table-bordered {
  border: 0px;
  box-shadow: 0 2px 5px 0 rgba(97, 95, 103, 0.5);
  background-color: $white;

  thead {
    th {
      @include caption-dark;
      border: 0px;
      padding: 16px;
      vertical-align: middle;
      border-bottom: 1px solid $dark;
    }
  }

  tbody {
    tr:hover {
      background-color: $white2;
    }
  }

  td {
    @include body-dark;
    border: 0px;
    padding: 16px;
    border-bottom: 1px solid $super-light;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.nav-tabs {
  background-color: $white;
  border-bottom: solid 1px #979797;

  .nav-link {
    color: $dark;
  }

  .nav-item.active,
  .nav-link.active {
    color: $theme1;
    border-radius: 2px;
    border-top-color: #979797;
    border-left-color: #979797;
    border-right-color: #979797;
    border-bottom-color: transparent;
  }

  .nav-item:not(.active):hover {
    background-color: $white2;
    border-color: transparent;
    border-bottom-color: #979797;
  }

  .nav-item.disabled,
  .nav-link.disabled {
    color: $light;
  }
}

.nav {
  .nav-item.disabled, .nav-link.disabled {
    color: $light;
  }
  .nav-item {
    .nav-link {
      @include medium;
      @include base-font;
      line-height: 14px;
      color: $black;
      padding: 20px 50px 16px 50px;
      border-bottom: 4px solid transparent;
    }

    .nav-link.active {
      border-bottom: 4px solid $theme1;
    }

    .nav-link:not(.active):hover {
      border-bottom: 4px solid $super-light;
    }
  }
}

a {
  @include body-link;

  &:hover {
    @include body-link-hover;
  }
}

a.btn {
  line-height: 14px;
}

textarea,
input,
.form-control {
  cursor: pointer;
  @include body-black;
  outline: none;
  box-shadow: none;

  &:focus {
    border: 1px solid $theme1;
    box-shadow: none;
  }
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    background-color: $white;
    border: 1px solid $super-light;
  }

  &:not(:disabled):hover + label {
    color: $theme1;
  }

  &:not(:disabled):hover + label:before {
    border: 1px solid $theme1;
  }
}

input[type="checkbox"] {
  & + label:before {
    border-radius: 2px;
  }

  &:checked + label:before,
  &:checked:hover + label:before {
    background-color: $theme1;
    border: 1px solid $theme1;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 11px;
    width: 1px;
    height: 1px;
    box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white, 4px 0 0 $white,
      5px 0 0 $white, 5px -1px 0 $white, 5px -2px 0 $white, 5px -3px 0 $white,
      5px -4px 0 $white, 5px -5px 0 $white, 5px -6px 0 $white, 5px -7px 0 $white,
      5px -8px 0 $white;
    transform: rotate(45deg);
  }
}

input[type="radio"] {
  & + label:before {
    border-radius: 7px;
  }

  &:checked + label:before,
  &:checked:hover + label:before {
    border: 3px solid $theme1;
  }
}

textarea,
input[type="email"],
input[type="text"],
input[type="password"],
input[type="number"] {
  border-radius: 2px;
  border: 1px solid $super-light;
  background-color: $white;
  padding: 6px 12px;
  resize: none;

  &:disabled {
    color: $super-light;
    border: 1px solid $super-light;
    background-color: $light-pink;
    cursor: default;
    &:hover {
      border: 1px solid $super-light;
    }
  }

  &::placeholder {
    @include body-super-light;
    opacity: 1;
  }

  &:hover {
    border: 1px solid $dark;
  }
}

.form-control.is-invalid {
  border: 1px solid $danger;
  background-image: none;

  &:focus {
    box-shadow: none;
  }
}

.form-control.is-valid {
  border: 1px solid $success;
  background-image: none;

  &:focus {
    box-shadow: none;
  }
}

.form-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

textarea:disabled:hover,
input:disabled:hover {
  border: solid 1px transparent;
}

textarea,
input {
  &:read-only {
    color: $dark;
    border: solid 1px transparent;
  }

  &:read-only:hover {
    border: solid 1px transparent;
  }

  &:read-only:focus {
    border: solid 1px transparent;
  }
}

label,
.col-form-label {
  @include body-black-medium;
  text-align: right;
  margin: auto 30px auto 0px;
}

.col-sm-3 {
  > .col-form-label {
    text-align: right;
  }
}

.col-sm-9 {
  > .col-form-label {
    text-align: left;
  }
}

.progress {
  margin: 4px 0px;
  height: 10px;
  border-radius: 5px;
  background-color: $white;
  border: 1px solid $theme1;
}

.progress-label {
  @include caption-dark;
}

.progress-bar {
  background-color: $theme1;

  .progress-bar-success {
    background-color: $success;
  }

  .progress-bar-info {
    background-color: $info;
  }

  .progress-bar-warning {
    background-color: $warning;
  }

  .progress-bar-danger {
    background-color: $danger;
  }
}

.breadcrumb {
  @include body-link;
  background-color: $white;

  &:hover {
    @include body-link-hover;
  }

  .active {
    color: $dark;
  }
}

.alert {
  border: 0;
  padding: 16px;
  border-radius: 0;
  max-width: 600px;
  text-align: center;
}

.alert-success {
  @include subheading-success;
  background-color: $light-success;
}

.alert-info {
  @include subheading-info;
  background-color: $light-info;
}

.alert-warning {
  @include subheading-warning;
  background-color: $light-warning;
}

.alert-danger {
  @include subheading-danger;
  background-color: $light-danger;
}

.badge {
  @include caption-font;
  height: 22px;
  color: $white;
  padding: 4px 10px;
  border-radius: 11px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }
}

.badge-dark {
  background-color: $dark;
}

.badge-light {
  background-color: $light;
}

.badge-danger {
  background-color: $danger;
}

.badge-info {
  background-color: $info;
}

.badge-warning {
  background-color: $warning;
}

.badge-success {
  background-color: $success;
}

.badge-primary {
  background-color: $primary1;
}

.badge-secondary {
  background-color: $theme1;
}

.modal-content {
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 #98969d;
}

.modal-backdrop {
  background-color: rgb(97, 95, 103);
}

.modal-header {
  @include headline-black;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 25px;

  .close {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
  }
}

.modal-footer,
.modal-body {
  padding: 20px;
}

a.page-link,
.page-link {
  @include medium;
  @include button-font;
  color: $dark;
  background-color: $white;
  border-color: $super-light;
}

a.page-link:hover,
.page-link:hover {
  color: $black;
  background-color: $white2;
}

.page-item.active .page-link {
  color: #fff;
  border-color: $theme1;
  background-color: $theme1;
}

.pagination {
  margin-left: auto;
  margin-right: auto;
}

select {
  height: 34px;
  border-radius: 2px;
  cursor: pointer;
  &:focus {
    border: 1px solid $theme1;
    outline: none;
  }
}

.tooltip {
  .arrow {
    visibility: hidden;
  }
}

.popover {
  max-width: 1000px;
  z-index: 3;
}