@import 'components.scss';

.bidAdjustmentJobList {
  background-color: $white;
  display: flex;
  flex-direction: column;

  .operationArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    min-height: 80px;
    background-color: $white;
    position: relative;
  }
  
  .adTypeNav {
    :global {
      .nav {
        padding-left: 20px;
        background-color: $white;
        flex-shrink: 0;
        z-index: 1;
        border-bottom: 1px solid $super-light;
      }
    }
  }

  .constraintTypeNav {
    margin-top: 30px;
    @include tabs;
    :global {
      .nav {
        flex-wrap: wrap;
        border-bottom: 1px solid $light;
      }
      .nav-link {
        white-space: nowrap;
      }
    }
  }

  > div:last-child {
    flex-grow: 1;
  }

  .purpose {
    max-width: 400px;
    overflow-wrap: break-word;
  }
}