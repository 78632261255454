@import 'colors.scss';
@import 'fonts.scss';

.productsFieldContainer {
  :global(.form-group) {
    align-items: center;
    margin-bottom: inherit;
  }
  :global(.col-sm-9) {
    > div {
      > div {
        width: 100% !important;
      }
    }
  }

  .tagsContainer {
    display: flex;
    position: relative;
  
    .tags {
      font-family: NotoSansSC,"Noto Sans SC", sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $dark;
      background-color: $white;
      :global(.react-tagsinput) {
        border-radius: 2px;
        border: 1px solid $super-light;
      }
    }
  
    .editIcon {
      margin-left: 5px;
      padding: 5px;
      width: 36px;
      height: 36px;
    }
  }

  .selectProductsRow {
    display: flex;
    width: max-content;
    :global {
      input.form-control {
        height: 38px;
      }
    }
  }
}

.productListModal {
  :global {
    .modal-dialog {
      overflow-x: auto;
      background-color: $white;
    }
    .modal-content {
      min-width: 1210px;
    }
    .modal-footer {
      position: absolute;
      bottom: 0px;
      border: none;
      box-shadow: none !important;
      height: 54px !important;
      padding: 0 30px;
    }
  }
}
