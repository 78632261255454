@import 'fonts.scss';
@import 'components.scss';

.productListArea {
  $topAreaHeight: 77px;
  $searchBarWidth: 280px;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  
  :global(.nav-link) {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .productListTitleBar {
    border: 1px solid $super-light;
    background-color: $white;
    border-left: none;
    border-right: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
  }
  .searchBarArea{
    display: flex;
    height: 66px;
    background-color: $white;
    :global(.search-bar) {
      width: $searchBarWidth;
      height: 38px;
      margin-left: auto;
      margin-right: 40px;
      border-radius: 4px;
    }
  }
  .seperationLine {
    width: 100%;
    height: 1px;
    background-color: #D7D7D7;
  }
  .contentArea {
    display: flex;
    flex-direction: column;
    background-color: $white2;
    flex-grow: 1;
    height: calc(100% - 197px);
    > div {
      height: 100%;
      padding-bottom: 0px;
    }

    @include tabs;
    :global(.nav) {
      padding-top: 30px;
      height: 72px;
    }
  }
  td {
    vertical-align: middle;
    padding: 20px 15px;
  }
  thead th,
  th:first-child,
  td:first-child {
    padding-left: 16px;
  }
  .selectCheckBox {
    min-width: 48px;
    height: auto;
    input {
      display: none;
    }
    input + label {
      margin-right: 0;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
    input:indeterminate + label::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 13px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
        4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
        8px 0 0 $white;
    }
  }

  .nameHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .nameCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      @include base-font;
      width: 100%;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
      word-break: break-word;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
  .imgCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .imgPreview {
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 75px;
      }
    }
  }
  tr:hover {
    td {
      background-color: $white2;
    }
  }

  .productId {
    min-width: 260px;
    width: 360px;
  }
  .imgLink {
    min-width: 150px;
    width: 250px;
  }
  .category {
    min-width: 250px;
    width: 350px;
    white-space: nowrap;
  }
  .listPrice {
    // width: 100px;
    white-space: nowrap;
  }
  table {
    table-layout: fixed;
  }

  :global {
    .react-bootstrap-table-pagination {
      position: sticky;
      left: 0;
      bottom: 0;
      z-index: 5;
      height: 54px;
      background-color: $white2;
      border-top: 1px solid $super-light;
      margin-left: 0px;
      margin-right: 0px;
    }
    .react-bootstrap-table-pagination-list {
      bottom: 10px !important;
    }
    .col {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media (max-width: 1240px) {
  .productListArea {
    table {
      table-layout: auto;
    }
  }
}
