@import 'colors.scss';

.selectableHeader {
  position: relative;
  padding-left: 50px;
  input {
    display: none;
  }
  input + label {
    position: absolute;
    left: 3px;
    top: -5px;
  }
  input:checked + label::before,
  input:indeterminate + label::before {
    background-color: $theme1;
    border: 1px solid $theme1;
  }
  input:indeterminate + label::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 13px;
    width: 1px;
    height: 1px;
    box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
      4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
      8px 0 0 $white;
  }
}