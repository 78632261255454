@import 'colors.scss';

.dropdownItem {
  &:active {
    color: #212529;
    background-color: $light-pink;
  }
}

.toggle {
  color: $theme1;

  &:hover {
    color: $theme1;
  }
}

.toggle::after {
  content: none;
}

.moveItem {
  padding-left: 50px;
}

.hide {
  display: none;
}

.pointDown {
  transform: rotate(90deg);
}

.DropdownToggleText {
  color: #212529;
}

.DropdownToggle {
  cursor: pointer;

  &:hover {
    background-color: $white2;
  }
}

.DropdownToggleOn {
  cursor: pointer;
  background-color: $light-pink;
}