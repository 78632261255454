@import './colors.scss';

@mixin medium {
  font-weight: 500;
}

@mixin regular {
  font-weight: 300;
}

@mixin base-font {
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: NotoSansSC, 'Noto Sans SC', sans-serif;
}

@mixin base-link {
  font-weight: 300;
  text-decoration: none;
}

@mixin icon-font {
  @include base-font;
  font-size: 10px;
  line-height: 1.4;
}

@mixin body-font {
  @include base-font;
  font-size: 14px;
  line-height: 22px;
}

@mixin headline-font {
  @include base-font;
  font-size: 20px;
  line-height: 24px;
}

@mixin subheading-font {
  @include base-font;
  font-size: 17px;
  line-height: 20px;
}

@mixin button-font {
  @include base-font;
  font-size: 14px;
  line-height: 14px;
}

@mixin caption-font {
  @include base-font;
  font-size: 12px;
  line-height: 14px;
}

@mixin text {
  @include base-font;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.74px;
}

@mixin body-link {
  @include body-font;
  @include base-link;
  color: $theme1;
}

@mixin body-link-hover {
  @include body-font;
  @include base-link;
  color: $theme1;
}

@mixin body-black {
  @include regular;
  @include body-font;
  color: $black;
}

@mixin body-dark {
  @include regular;
  @include body-font;
  color: $dark;
}

@mixin body-black-medium {
  @include medium;
  @include body-font;
  color: $black;
}

@mixin body-dark-medium {
  @include medium;
  @include body-font;
  color: $dark;
}

@mixin body-super-light {
  @include regular;
  @include body-font;
  color: $super-light;
}

@mixin headline-black {
  @include medium;
  @include headline-font;
  color: $black;
}

@mixin subheading-danger {
  @include medium;
  @include subheading-font;
  color: $danger;
}

@mixin subheading-info {
  @include medium;
  @include subheading-font;
  color: $info;
}

@mixin subheading-success {
  @include medium;
  @include subheading-font;
  color: $success;
}

@mixin subheading-warning {
  @include medium;
  @include subheading-font;
  color: $warning;
}

@mixin subheading-dark {
  @include medium;
  @include subheading-font;
  color: $dark;
}

@mixin button-black {
  @include button-font;
  @include medium;
  color: $black;
}

@mixin button-dark {
  @include button-font;
  @include medium;
  color: $black;
}

@mixin button-theme {
  @include button-font;
  @include medium;
  color: $theme1;
}

@mixin button-primary {
  @include button-font;
  @include medium;
  color: $primary1;
}

@mixin button-white {
  @include button-font;
  @include medium;
  color: $white;
}

@mixin button-super-light {
  @include button-font;
  @include medium;
  color: $super-light;
}

@mixin none {
  @include regular;
  @include body-font;
  color: rgba(255, 255, 255, 0)
}

@mixin caption-dark {
  @include caption-font;
  color: $dark;
}

@mixin caption-white {
  @include caption-font;
  color: $white;
}

@mixin footer-white  {
  color: $white;
  line-height: 1.75;
}