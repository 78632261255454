$theme1: #0090EB;
$theme2: #068d9d;
$theme3: #d1eff1;
$theme4: #05707d;
$theme5: #eff3f6;
$theme6: #A1D8F9;
$theme7: #0090EB;
$theme8: #5FA6EC;

$primary1: #0090EB;
$primary2: #097CE5;
$primary3: #328CE7;
$primary4: #416BC3;
$primary5: #f6f4ff;
$primary6: #191C1D;

$black: #242328;
$black2: #414141;
$dark: #615f67;
$light: #a7a6ac;
$super-light: #d8d8d8;

$danger: #ce555c;
$light-danger: #ffdfe0;
$success: #76b793;
$light-success: #e8f5eb;
$deep-warning: #ee75ee;
$warning: #ffa953;
$light-warning: #fff2e4;
$info: #99d4e9;
$light-info: #eefbff;

$white: #ffffff;
$white2: #f7f7f7;
$white3: #f4f4f4;
$light-pink: #f1f1f1;
