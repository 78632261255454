@import 'fonts.scss';
@import 'components.scss';

$columnHeadGap: 40px;
$columnBodyGap: 18px;

.l1ObjectList {
  $topAreaHeight: 77px;
  min-height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .listConditionArea {
      flex-shrink: 0;
      padding-top: 30px;
      background-color: $white;
      @include tabs;
      display: flex;
      flex-direction: row;
      position: relative;
      :global(.nav) {
        width: 100%;
      }
      :global(.nav-link) {
        display: flex;
        align-items: center;
      }
      .metricsOptionsContainer {
        position: absolute;
        top: 17px;
        right: 30px;
        .metricsOptions {
          width: 200px;
          z-index: 10;
        }
      }
    }
  }
  th {
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  thead th,
  td {
    &.editBtns {
      width: 0;
      min-width: 0;
      max-width: 0;
      padding: 0px;
    }
  }
  th:first-child,
  td:first-child {
    padding-left: 14px;
  }
  .floatingEditArea {
    display: none;
  }
  .selectCheckBox {
    height: auto;
    min-width: 40px;
    input {
      display: none;
    }
    input + label {
      margin-right: 0;
      padding-right: 16px;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
    input:indeterminate + label::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 13px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
        4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
        8px 0 0 $white;
    }
  }
  .nameHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .nameCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
    .info {
      padding: 16px 0px;
    }
  }
  tr:hover {
    .floatingEditArea {
      display: flex;
    }
    td {
      background-color: $white2;
    }
  }
  .titleBar {
    width: 100%;
    height: $topAreaHeight;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    position: relative;
  }
  .filterArea {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: $white;
    :global(.search-bar) {
      width: 370px;
      margin: 0px;
      position: absolute;
      right: 30px;
    }
    .createBtn {
      color: $white;
    }
  }
  .stateCell {
    @include base-font;
    transform: scale(0.95);
    font-size: 12px;
    font-weight: 500;
    color: $white;
    height: 22px;
    width: max-content;
    border-radius: 11px;
    background-color: $theme2;
    line-height: 22px;
    padding: 0px 10px;
    box-sizing: content-box;
    &.budgetRemain,
    &.deleted {
      background-color: $danger;
    }
    &.deactive {
      background-color: $black;
    }
    &.stopping {
      color: $theme4;
      border: 1px solid $theme4;
      background-color: $white;
    }
  }
  .deliveryCell {
    display: flex;
    flex-direction: column;
    
    .progressCell {
      .progressContent {
        display: flex;
        align-items: center;
        white-space: nowrap;
        .progress {
          width: 60px;
          margin-right: 5px;
        }
        @include base-font;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.17;
        color: $dark;
      }
    }
    .tagsCell {
      display: flex;
      flex-wrap: wrap;
      width: 150px;
      grid-gap: 4px;
      margin-top: 10px;
    }
  }
  .budgetCell {
    display: flex;
    flex-direction: column;
    
    .tagsCell {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      grid-gap: 4px;
      margin-top: 10px;
    }
  }
  .l1ObjectId {
    width: calc(348px + #{$columnHeadGap});
    padding-right: $columnHeadGap;
  }
  :global(.summaryRow) {
    .l1ObjectId {
      div {
        padding-left: 40px;
      }
    }
  }
  .impres,
  .clicks,
  .cpc,
  .ctr,
  .spent {
    white-space: nowrap;
  }
  .impres,
  .clicks,
  .cpc,
  .ctr,
  .spent {
    text-align: right;
  }
  .state {
    white-space: nowrap;
    width: calc(150px + #{$columnBodyGap});
    padding: 0px calc(0px + #{$columnBodyGap}) 0px 16px;
  }
  .delivery {
    white-space: nowrap;
    width: calc(200px + #{$columnBodyGap});
    padding: 16px calc(0px + #{$columnBodyGap}) 16px 16px;
  }
  .budget {
    white-space: nowrap;
    width: calc(160px + #{$columnBodyGap});
    padding: 16px calc(0px + #{$columnBodyGap}) 16px 16px;
  }
  .basicSpent {
    white-space: nowrap;
    width: calc(150px + #{$columnBodyGap});
    padding: 0px calc(0px + #{$columnBodyGap}) 0px 16px;
  }
  .channel {
    white-space: nowrap;
    width: 120px;
    padding: 0px 0px 0px 16px;
  }
  .createL1Object {
    margin-left: 30px;
    display: inline-block;
  }
  .tipContainer {
    position: relative;
  }
  .tipContainer.block {
    &:hover {
      cursor: not-allowed;
      .tooltip {
        display: block;
      }
    }
    a {
      pointer-events: none;
      background-color: rgba($primary6, 0.12);
      border: rgba($primary6, 0.12);
      color: $white;
      opacity: 1;
    }
  }
  .tooltip {
    display: none;
    position: absolute;
    left: 65px;
    z-index: 2;
    font-size: 12px;
    font-weight: 500;
    @include base-font;
    line-height: 1.17;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: $black;
    border-radius: 0.25rem;
    top: unset;
    bottom: -25px;
    height: auto;
  }
}

.tableContainer {
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: 1px solid $super-light;
  :global(.table) {
    margin-bottom: 0;
  }
}
