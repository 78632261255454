@import 'fonts.scss';

.info {
  .name {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    display: block;
    max-width: 360px;
    &.active {
      color: $theme1;
    }
    &.link {
      color: $theme1;
      word-break: break-word; 
    }
  }
  .id {
    @include base-font;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    color: $dark;
    padding: 4px 0px;
  }
}
