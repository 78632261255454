@import 'fonts.scss';

.privacyPolicy {
  @include base-font;
  font-size: 12px;
  line-height: 1.17;
  color: $theme1;
  height: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
    height: 14px;
  }
}