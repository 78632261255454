@import "colors.scss";

.limitationSet {
    position: relative;
     :global {
        .react-tagsinput-input {
            display: none;
        }
    }
    
    .tags {
        flex: 1;
    }
    
    .hasError {
        :global(.react-tagsinput) {
            border-color: $danger;
        }
    }
    
    .emptySpace {
        min-width: 32px;
        max-width: 32px;
    }

    :global(.errorTip) {
       position: relative; 
    }
}
