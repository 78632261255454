@import './inputField.module.scss';

.withoutBorder {
  border: none !important;
}

:global {
  .input-group {
    .form-control {
      height: auto;
    }
  }
}

:global(.input-group-text) {
  font-family: NotoSansSC,"Noto Sans SC", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: $dark;
  background-color: $white;
  border-radius: 2px;
  border: 1px solid $super-light;
  &.disabled {
    border: none;
    z-index: 1;
    border-right: 1px solid $super-light;
    color: $super-light;
    background-color: $light-pink;
  } 
}