@import 'fonts.scss';
@import 'colors.scss';
@import 'components.scss';

.batchDelete {
  position: relative;
  &.not-allow {
    cursor: not-allowed;
  }
  > .tooltip {
    display: none;
  }
  &:hover {
    > .tooltip {
      display: block;
      position: absolute;
      background-color: #fff;
      color: $dark;
      font-size: 12px;
      padding: 0 5px;
      white-space: nowrap;
      right: -120px;
      top: 20px;
    }
  }
}

.creativeList {
  background-color: $white2;
  height: 100%;
  display: flex;
  flex-direction: column;
  $topAreaHeight: 77px;
  @include tabs;
  :global {
    .nav {
      flex-wrap: wrap;
      border-bottom: 1px solid $light;
    }
    .nav-link {
      white-space: nowrap;
    }
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
    user-select: none;
    background-color: $white;
  }
  th {
    outline: none;
    height: 46px;
    white-space: nowrap;
  }
  td:nth-last-child(2) {
    width: 50% !important;
  }
  .creativeCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .info {
      max-width: 195px;
      span.creativeName,
      a.creativeName {
        @include base-font;
        width: 195px;
        font-size: 14px;
        line-height: 1.57;
      }
      a.creativeName {
        color: $theme1;
      }
      span.creativeName {
        color: $dark;
      }
      .creativeID {
        @include base-font;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.2;
        color: $dark;
        padding: 4px 0px;
      }
    }
    .creativeReview {
      min-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .productCell {
    > div {
      min-width: 215px;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }

  .summaryRow {
    pointer-events: none;
    height: 32px;
    background-color: $super-light;
    td {
      @include base-font;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
      background-color: transparent;
    }
    .bannerId {
      background-color: $super-light !important;
      padding-left: 64px;
    }
  }
  table {
    margin-bottom: 0;
  }
  thead th,
  td {
    border-bottom: 1px solid $super-light;
    .detailBtn,
    .editBtn {
      margin-left: 10px;
      visibility: hidden;
      cursor: pointer;
      color: $dark;
    }
    .detailBtnSpace {
      margin-left: 10px;
      width: 16px;
    }
    &.bannerId {
      min-width: 284px;
      max-width: 284px;
      width: 284px;
      padding-left: 0px;
      padding-right: 15px;
      background-color: $white;
      word-break: break-all;
      user-select: text;
      .nameIdHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .selectCheckBox {
        width: 14px;
        margin-left: 20px;
        margin-right: 30px;
        input {
          display: none;
        }
        input:checked + label::before,
        input:indeterminate + label::before {
          background-color: $theme1;
          border: 1px solid $theme1;
        }
        input:indeterminate + label::after {
          content: '';
          position: absolute;
          left: 2px;
          top: 13px;
          width: 1px;
          height: 1px;
          box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
            4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
            8px 0 0 $white;
        }
      }
    }
    &.status {
      min-width: 180px;
      padding-left: 10px;
      padding-right: 15px;
    }
    &.preview {
      width: 280px;
      max-width: 280px;
      padding-left: 10px;
      padding-right: 15px;
    }
    &.adTitle,
    &.adDesc,
    &.adMessage,
    &.bannerUrl {
      max-width: 220px;
      min-width: 220px;
      width: 220px;
      padding-left: 10px;
      padding-right: 15px;
      word-break: break-word;
      @include base-font;
      font-size: 12px;
      line-height: 1.33;
      color: $dark;
      div:nth-child(1),
      a {
        margin-left: 0px;
      }
    }
    &.bannerUrl {
      $font-size: 12px;
      $line-height: 16px;
      $lines-to-show: 3;
      word-break: break-all;
      a {
        display: -webkit-box;
        max-width: max-content;
        max-height: $line-height * $lines-to-show;
        font-size: $font-size;
        line-height: $line-height;
        -webkit-line-clamp: $lines-to-show;
        /*! autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $theme1;
      }
    }
    &.bindingCampaign {
      max-width: 230px;
      min-width: 230px;
      width: 230px;
      padding-left: 10px;
      padding-right: 25px;
      .bindState {
        width: max-content;
        .enableBindState,
        .disableBindState,
        .disableBindState,
        .beforeStartTimeState,
        .afterEndTimeState,
        .notBindState {
          line-height: 18px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 12px;
          @include base-font;
          font-size: 12px;
          font-weight: 500;
          color: $white;
          width: fit-content;
        }
        .enableBindState {
          background-color: #99d4e9;
        }
        .disableBindState {
          background-color: $black;
          margin-top: 4px;
        }
        .notBindState {
          border: 1px solid $super-light;
          background-color: $white;
          color: $light;
        }
      }
    }
    &.sponsor {
      max-width: 180px;
      min-width: fit-content;
      width: fit-content;
      padding-left: 10px;
      word-break: break-all;
      div:nth-child(1) {
        overflow-wrap: break-word;
        max-width: 160px;
        width: max-content;
        @include base-font;
        font-size: 14px;
        line-height: 1.57;
        color: $dark;
      }
    }
    &.limitations {
      min-width: 320px;
      width: fit-content;
      padding-left: 10px;
      padding-right: 15px;
      .limitationContent {
        display: grid;
        grid-template-columns: max-content 12px 1fr;
        .limitationTitle {
          grid-column: 1 / 2;
        }
        .colon {
          grid-column: 2 / 3;
          white-space: pre;
        }
        .limitationValue {
          min-width: 80px;
          grid-column: 3 / 4;
        }
        & > div {
          overflow-wrap: break-word;
          max-width: 110px;
          width: max-content;
          @include base-font;
          font-size: 14px;
          line-height: 1.57;
          color: $dark;
          span.include {
            color: $success;
          }
          span.exclude {
            color: $danger;
          }
          span.preferred {
            color: #72c5e2;
          }
          span.nonePreferred {
            color: $warning;
          }
        }
      }
    }
    &.approvalStatus {
      min-width: 230px;
      width: 230px;
      padding-left: 10px;
    }
    &.utime {
      min-width: 100px;
      width: 100px;
      padding-left: 10px;
    }
    &.editBtns {
      width: 0;
      padding: 0px;
    }
  }
  .floatingEditArea {
    display: none;
  }
  tr:hover {
    .floatingEditArea {
      display: flex;
    }
    .editBtn,
    .detailBtn {
      visibility: visible;
    }
    td {
      background-color: $white2;
    }
  }
  .operationArea {
    flex-shrink: 0;
    background-color: $white;
    border-bottom: 1px solid $super-light;
    display: flex;
    align-items: center;
    height: $topAreaHeight;
    a {
      @include base-font;
      margin-left: 30px;
      height: 34px;
      border-radius: 2px;
      padding: 10px 30px;
      display: flex;
      align-items: center;
    }
    :global(.search-bar) {
      width: 280px;
      margin: 0px;
      position: absolute;
      right: 40px;
    }
  }
  .contentArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $white2;
    :global(.nav) {
      flex-shrink: 0;
      padding-top: 30px;
    }
    .listContainer {
      flex-grow: 1;
    }
  }
  .titleBar {
    height: $topAreaHeight;
  }
  .hoverableTooltip {
    // font-size: 20px !important;
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
}
