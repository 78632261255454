@import "components.scss";

.app-header {
  margin: 0px;
  width: 100%;
  height: 50px;
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 6;
  color: $white;
  background-color: $theme7;

  .hidden {
    display: none;
  }

  ul {
    @include floating-menu($white);
    padding: 0;
    color: $black;
    display: block;
    min-width: 170px;
    position: absolute;
    list-style-type: none;
    list-style-position: unset;
    margin-left: -15px;

    li {
      @include body-font;
      height: 42px;
      cursor: pointer;
      margin: auto 0px;
      line-height: 42px;
      padding-left: 12px;
    }

    li:hover {
      background-color: $white2;
    }
  }

  .toggle {
    margin: auto auto auto 0px;
    font-size: 20px;
    cursor: pointer;
    color: $white;
    opacity: 50%;
    &:hover {
      color: $white;
      opacity: 70%;
    }
  }

  .logo {
    margin: auto 20px;

    img {
      height: 22px;
    }
  }

  .account-info {
    @include button-white;
    padding-left: 15px;
    line-height: 50px;
    color: $white;
    .menu-title {
      display: flex;
      cursor: pointer;

      .arrow {
        @include arrow-down($white);
        margin: auto 15px;
      }
    }

    &:hover {
      background-color: $theme8;
    }
  }
}

.app-header-modal {
  .react-bootstrap-table-pagination {
    position: sticky;
    left: 0;
    bottom: 0;
    z-index: 5;
    height: 74px;
    background-color: $white;
    border-top: 1px solid $super-light;
    margin-left: 0px;
    margin-right: 0px;
  }
  .react-bootstrap-table-pagination-list {
    bottom: 20px !important;
  }
  .modal-footer {
    position: absolute;
    bottom: 0px;
    border: none;
    box-shadow: none !important;
    height: 74px !important;
    padding: 0 30px;
    z-index: 6 !important;
  }
}