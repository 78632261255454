@import 'components';

.orderFormContainer {
  @include tab-form-content;
  .fieldContent {
    overflow: visible;
  }
  background-color: $white3;
  padding-bottom: 56px;
  height: 100%;

  :global(form) {
    background-color: $white3;
  }
  
  .orderBudgetWithButton {
    margin-bottom: 0;
  }

  .titleArea  {
    background-color: $white;
    height: 120px;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
  }

  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }

  .editOrderForm {
    .budgetWithButton {
      display: flex;
      :global(.form-group) {
        margin-bottom: 0;
      }
    }
    .budgetWithButton, .marginWithButton, .orderType {
      :global(.col-sm-9) {
        .budgetLabel {
          :global(.input-group-text) {
            padding: 0;
            line-height: 22px;
          }
        }
        .marginLabel {
          label {
            margin: 0;
            line-height: 1.57;
          }
          span {
            margin-left: 5px;
            padding: 0px;
          }
        }
        .budgetLabel {
          span {
            margin-right: 5px;
          }
        }
        > :global(.input-group) {
          max-width: 205px;
          min-width: 205px;
        }
        button {
          top: 0;
          left: 220px;
          position: absolute;
          @include base-font;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          color: $dark;
          height: 35px;
          margin-left: 20px;
        }
      }
    }

    :global(.bp3-input-group):first-child {
      cursor: not-allowed;
      > input {
        pointer-events: none;
      }
    }
  }
  
  :global(.col-sm-3) {
    label {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
    }
  }

  :global(.col-sm-9) {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $black;
  }

  :global(.col-sm-9) {
    input {
      max-width: 424px;
    }
    :global(.input-group) {
      max-width: 424px;
    }
    
    textarea {
      height: 100px;
      max-width: 424px;
    }
  }

  .dateRange {
    width: 346px
  }

  .buttonArea {
    button {
      margin-left: 20px;
    }
    button:first-child {
      margin-left: 30px;
    }

    padding-top: 30px;
    padding-bottom: 60px;
    background-color: $white3;
  }

  .dateRange~span.hint {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
  }
  
  .tips~span.hoverTip {
    display: none;
    position: absolute;
    left: 380px;
    top: 10px;
    margin-left: 10px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    color: $theme1;
  }
  
  .tips:hover~span.hoverTip {
    display: inline-block;
  }

  :global(.text-danger) {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    margin-top: 2px;
    width: 100%;
  }

  .warning {
    color: $warning;
    margin-left: 5px;
    font-size: 12px;
  }
  .ageSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      width: 75px;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
