@import 'colors.scss';

.fieldContent {
  display: grid;
  grid-template-columns: auto 1fr;
}

.fieldComponent {
  display: flex;
  grid-column: 1 / 2;
  :global(.col-form-label) {
    text-align: left;
    color: $dark;
  }
}

.error {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.flexibleFieldContent {
  display: flex;
}

.flexibleFieldComponent {
  width: auto;
  :global(.col-form-label) {
    text-align: left;
    color: $dark;
  }
}

.flexibleFieldContent:hover {
  .hoverHint {
    visibility: visible;
  }
}

.flexible {
  display: flex;
  position: relative;
  width: auto;
  padding-right: 15px;
  padding-left: 15px;

  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: NotoSansSC, 'Noto Sans SC', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  text-align: left;
  margin: auto 0px;
}

.containAdditionalTexts {
  grid-template-columns: auto auto 1fr;
}

.preText {
  padding: 7px 10px 7px 0px;
  font-family: NotoSansSC, 'Noto Sans SC', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: $dark;
}

.postText {
  grid-row: 1;
  grid-column-start: 2;
  padding: 7px 15px 7px 10px;
  font-family: NotoSansSC, 'Noto Sans SC', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: $dark;
}

.postComponent {
  grid-row: 1;
  grid-column-start: 2;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
}

.hoverHint {
  visibility: hidden;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
  color: $theme1;
  grid-row: 1;
  grid-column: 2 / 3;
  height: 35px;
  line-height: 35px;
}

.fieldContent:hover {
  .hoverHint {
    visibility: visible;
  }
}

.hint {
  display: block;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #98969d;
  margin-top: 2px;
  width: 100%;
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.permanentHint {
  grid-column: 1 / 3;
}


.fullWidth {
  width: 100%;
  margin-bottom: 10px;
  .fieldContent {
    width: 100%;
  }
}

.required::before {
  content: '* ';
  color: $danger;
}