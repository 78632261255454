@import 'fonts.scss';

.imageSize {
  @include base-font;
  height: 14px;
  font-size: 12px;
  line-height: 1.17;
  color: $dark;
  position: absolute;
  bottom: 0;
}

.richProductNativeMediaSummary {
  position: relative;
  overflow: hidden;
  max-width: 900px;
  &.width2440 {
    height: 106px;
    iframe {
      transform: scale(0.738);
      transform-origin: 0 0;
      border: none;
    }
  }
  &.width960 {
    height: 140px;
    iframe {
      border: none;
    }
  }
}