@import 'fonts.scss';

.bindCreativeStateContent {

  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 30px;
  }

  .searchAndSortArea {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 30px;
  
    :global(.search-bar) {
      margin-left: 0;
      width: 280px;
    }

    .sortBtns {
      display: flex;
      flex-direction: row;
  
      .sortBtn {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 34px;
        height: 34px;
        color: $dark;
        background-color: $white;
        border: 1px solid $super-light;
        padding-left: 30px;
        padding-right: 30px;
        cursor: pointer;
        &.selected {
          color: $white;
          background-color: $theme1;
          border-color: $theme1;
        }
        &:first-child {
          border-radius: 2px 0px 0px 2px;
          border-right: none;
        }
        &:last-child {
          border-radius: 0px 2px 2px 0px;
          border-left: none;
        }
      }
    }
  }
  
  .filterArea {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 30px;

    .filterBtn {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 34px;
      height: 34px;
      color: $dark;
      background-color: $white;
      border: 1px solid $super-light;
      padding-left: 30px;
      padding-right: 30px;
      cursor: pointer;
  
      &.selected {
        color: $white;
        background-color: $theme1;
        border-color: $theme1;
      }
      &:first-child {
        border-radius: 2px 0px 0px 2px;
      }
      &:last-child {
        border-radius: 0px 2px 2px 0px;
        border-right: 1px solid $super-light;
      }
      &:not(:last-child) {
        border-left: 1px solid $super-light;
        border-right: none;
      }
    }
  }

  .selectAllCheckBox {
    min-height: 21px;
    margin-left: 30px;
    margin-bottom: 20px;

    input {
      display: none;
    }
  
    input + label::before, input:hover + label::before {
      border-radius: 2px;
      border: 2px solid $dark;
      background-color: $white;
      margin-bottom: 2px;
    }

    input + label {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
    }
  
    input:checked + label::before, input:indeterminate + label::before {
      border: 1px solid $theme1;
      background-color: $theme1;
    }

    input:checked + label::after {
      top: 11px;
      left: 3px;
      transform: scale(0.7) rotate(45deg);
    }
  
    input:indeterminate + label::after {
      content: "";
      position: absolute;
      left: 2px;
      top: 11px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white, 4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white, 8px 0 0 $white;
    }
  }

  .creatives {
    background-color: $white3;
    padding: 30px;
    padding-right: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
  }
}