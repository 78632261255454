.cloudCostList {

  height: 100%;

  table {
    table-layout: fixed;
  }

  .eyeBtn {
    visibility: hidden;
  }

  tr:hover {
    .eyeBtn {
      visibility: visible;
    }
  }
}

.certificatePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  > div {
    width: max-content;
    height: max-content;
  }
}