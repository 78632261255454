@import 'fonts.scss';

.operationArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  min-height: 80px;
  background-color: $white;
  position: relative;

  :global(.search-bar) {
    width: 370px;
    margin: 0px;
    position: absolute;
    right: 30px;
  }
}
