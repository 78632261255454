@import 'fonts.scss';

.creativeList {
  height: 100%;
  .creativeCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .info {
      min-width: 80px;
      max-width: 172px;
      .creativeName {
        @include base-font;
        width: 172px;
        // height: 44px;
        font-size: 14px;
        line-height: 1.57;
        color: $dark;
        word-break: break-word;
      }
      div {
        @include base-font;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.2;
        color: $dark;
        padding: 4px 0px;
      }
    }
    .warning {
      color: #fad201;
      margin-left: 5px;
      font-size: 12px;
    }
  }
  .effectiveStatusCell {
    margin-top: -10px;
    cursor: pointer;
  }
  .duration {
    margin-top: 10px;
    font-size: 12px;
    > div {
      white-space: nowrap;
    }
  }

  .tableContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    > div {
      padding-bottom: 0px !important;
    }
  }
  
  :global(table) {
    box-shadow: none;
    margin-bottom: 0;
  }

  :global(input[type="checkbox"]) {
    position: relative;
    opacity: 1;
  }

  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
    input:checked + label::before, input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
  }

  :global(.sortable) {
    cursor: pointer;
  }

  th {
    outline: none;
    height: 46px;
    white-space: nowrap;
  }
  .previewCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .creativeReview {
      min-height: 140px;
      display: flex;
      align-items: center;
    }
  }

  .approvalColumn {
    display: block;
    div {
      display: flex;
    }
  }

  .approval {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .reviewBtn {
    margin-left: 10px;
    cursor: pointer;
  }

  .approvalContent {
    font-size: 12px;
    color: $white;
    height: 18px;
    border-radius: 2px;
    line-height: 18px;
    width: 24px;
    box-sizing: content-box;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.none {
      @include base-font;
      color: $dark;
      line-height: 22px;
    }
    &.new {
      background-color: $dark;
    }
    &.pending {
      background-color: $warning;
    }
    &.approved {
      background-color: $success;
    }    
    &.denied {
      background-color: $danger;
    }
    &.fixed {
      background-color: $black;
    }
  }

  .creativeFloatingEditArea {
    display: none;
  }
  .creativeIdColumn {
    max-width: 284px;
    min-width: 284px;
    width: 284px;
    word-break: all;
  }

  .preveiwColumn {
    max-width: 280px;
    width: 240px;
    min-width: 240px;
    padding-left: 0px;
    padding-right: 15px;
  }

  .impsColumn, .clicksColumn {
    min-width: 80px;
  }

  .approvalStatusColumn, .statusColumn, .deliveryColumn {
    width: 220px;
  }
  
  .impsColumn,
  .clicksColumn,
  .ctrColumn {
    white-space: nowrap;
    min-width: 100px;
    text-align: right;
    .creativeCell {
      justify-content: flex-end;
    }
  }

  .resultsColumn {
    > div {
      position: relative;
      .value {
        color: $dark;
      }
      .objective {
        font-size: 10px;
        color: $light;
        position: absolute;
        right: 0;
      }
    }
  }

  tbody > tr:hover {
    .creativeFloatingEditArea {
      display: flex;
    }
    .creativeEditBtn, .detailBtn {
      visibility: visible;
    }
    background-color: $white2;
  }
  .summaryRow {
    pointer-events: none;
    height: 32px;
    background-color: $super-light;
    td {
      @include base-font;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
    }
    .creativeIdColumn {
      background-color: $super-light !important;
      padding-left: 64px;
    }
  }
  :global(.editCreativeArea){
    position: relative;
  }

  .idHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 3px;
  }

  .selectCheckBox {
    width: 14px;
    margin-left: 3px;
    margin-right: 33px;
    input {
      display: none;
    }

    input:checked + label::before, input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }

    input:indeterminate + label::after {
      content: "";
      position: absolute;
      left: 2px;
      top: 13px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white, 4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white, 8px 0 0 $white;
    }
  }
  :global {
    .react-bootstrap-table-pagination {
      position: sticky;
      left: 0;
      bottom: 0;
      z-index: 5;
      height: 74px;
      border-top: 1px solid $super-light;
      margin-left: 0px;
      margin-right: 0px;
      background-color: $white3;
    }
    .react-bootstrap-table-pagination-list {
      bottom: 21px !important;
    }
    .col {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.deliveryAdReviewSection {
  margin-bottom: 10px;
  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }
  li {
    overflow-wrap: break-word;
  }
}
